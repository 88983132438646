import dayjs from 'dayjs';
import en from 'dayjs/locale/en';
import ja from 'dayjs/locale/ja';
import { getLocale } from '@/utils/locale';
import { getDefaultLang } from '@/utils/language';
import { getDatetimeFormat } from '@/utils/dates';

dayjs.locale('en', en);
dayjs.locale('ja', ja);

export default function (val, opts = { format: getDatetimeFormat() }) {
  if (!val) {
    return '–';
  }

  const d = new Date(val);
  if (!d.toJSON()) {
    return '-';
  }

  return dayjs(d)
    .locale(getLocale() || getDefaultLang())
    .format(opts.format);
}
