import store from '@/store';

function hasRole(el, binding) {
  const { value } = binding;
  const roles = store.getters && store.getters.roles;
  if (value && value instanceof Array) {
    if (value.length > 0) {
      const roleValues = value;

      const hasRole = roles.some((role) => roleValues.includes(role));

      if (!hasRole) {
        // eslint-disable-next-line
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  } else {
    throw new Error("need roles! Like v-roles=\"['hkp', 'hk']\"");
  }
}

export default {
  mounted(el, binding) {
    hasRole(el, binding);
  },
  beforeUpdate(el, binding) {
    hasRole(el, binding);
  },
};
