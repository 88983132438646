export const ROLES = {
  ADMIN: 'ADMIN',
  SALES: 'SALES',
  OPERATION: 'OPS',
  CUSTOMER_SUPPORT: 'CS',
  PROPERTY_OWNER: 'PO',
  HOUSEKEEPING_COMPANY: 'HC',
  HOUSEKEEPER: 'HKP',
  SALES_PROMOTION: 'PROMOTION',
  DATA_INSIGHT: 'DI',
};

export const INTERNAL = [
  ROLES.ADMIN,
  ROLES.OPERATION,
  ROLES.CUSTOMER_SUPPORT,
  ROLES.PROPERTY_OWNER,
];

export const REPORTS = [
  ROLES.ADMIN,
  ROLES.SALES,
  ROLES.OPERATION,
  ROLES.PROPERTY_OWNER,
  ROLES.SALES_PROMOTION,
  ROLES.DATA_INSIGHT,
];
