export default function (record, retString = true) {
  const types = [];
  if (record) {
    const roles = record.roles ? record.roles : [];
    for (let i = 0; i < roles.length; i += 1) {
      types.push(roles[i].name);
    }
  }
  if (retString) {
    return types.length ? types.join(', ') : '-';
  }
  return types;
}
