import store from '@/store';

function checkPermission(el, binding) {
  const { value } = binding;
  const permissions = store.getters && store.getters.permissions;
  if (value && value instanceof Array) {
    if (value.length > 0) {
      const permissionValues = value;

      const hasPermission = permissions.some((permission) => permissionValues.includes(permission));

      if (!hasPermission) {
        // eslint-disable-next-line
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  } else {
    throw new Error("need permissions! Like v-permission=\"['add-user','view-user']\"");
  }
}

export default {
  mounted(el, binding) {
    checkPermission(el, binding);
  },
  beforeUpdate(el, binding) {
    checkPermission(el, binding);
  },
};
