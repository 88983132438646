import { getNavigatorLanguage } from '@/utils/util';

export default function (
  amount,
  options = {
    factionDigits: 0,
  },
) {
  const { factionDigits } = options;
  const { value, currency } = amount;
  const locale = getNavigatorLanguage();
  if (value == null) {
    return '–';
  }
  if (Number.isNaN(Number(value))) {
    return 'N.A';
  }
  const intl = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: factionDigits,
    minimumFractionDigits: factionDigits,
  });
  return intl.format(value);
}
