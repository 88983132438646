// eslint-disable-next-line no-unused-vars
const containment = true;
// eslint-disable-next-line no-unused-vars
const getViewportSize = function () {
  const width = window.innerWidth
    || document.documentElement.clientWidth || document.body.clientWidth;
  const height = window.innerHeight
    || document.documentElement.clientHeight || document.body.clientHeight;
  return {
    width,
    height,
  };
};

// eslint-disable-next-line no-unused-vars
function draggable(el, binding) {
  const dlg = document.getElementsByClassName('ant-modal')[0];
  if (!dlg) return;
  const title = document.getElementsByClassName('ant-modal-title')[0];
  if (title) {
    title.style.userSelect = 'none';
    title.style['-ms-user-select'] = 'none';
    title.style['-moz-user-select'] = 'none';
    title.style.cursor = 'move';
  }

  dlg.offsetX = 0;
  dlg.offsetY = 0;

  const move = function (e) {
    let left = e.pageX - dlg.offsetX;
    let top = e.pageY - dlg.offsetY;
    if (containment) {
      // Constrains left & top
      left = Math.max(left, 0);
      top = Math.max(top, 0);
      // Constrains right & bottom
      const viewport = getViewportSize();
      left = Math.min(left, viewport.width - dlg.offsetWidth);
      top = Math.min(top, viewport.height - dlg.offsetHeight);
    }
    dlg.style.margin = '0px';
    dlg.style.left = `${left}px`;
    dlg.style.top = `${top}px`;
  };

  const up = function () {
    window.removeEventListener('mousemove', move);
    window.removeEventListener('mouseup', up);
  };

  const down = function (e) {
    dlg.offsetX = e.pageX - dlg.offsetLeft;
    dlg.offsetY = e.pageY - dlg.offsetTop;
    window.addEventListener('mousemove', move);
    window.addEventListener('mouseup', up);
  };

  const header = document.getElementsByClassName('ant-modal-title')[0];
  if (header) {
    header.addEventListener('mousedown', down);
  }
}

export default {
  updated(el, binding) {
    setTimeout(() => {
      draggable(el, binding);
    }, 300);
  },
};
