export const TYPES = {
  NEW_LOST_FOUND: 'new-lost-and-found',
  NEW_OUT_OF_ORDER: 'new-out-of-order',
  NEW_INCIDENT: 'new-incident-title',
  REJECTED_EXPRENSE: 'rejected-expense',
  LOW_STOCK: 'low-stock',
  CANCEL_RESERVATION: 'cancel-reservation',
  TAKEN_ROOM_ASSIGNMENT: 'taken-room-assignment',
  NEW_ROOM_ASSIGNMENT: 'new-room-assignment',
  APPROVED_ROOM_ASSIGNMENT: 'approved-room-assignment',
  DOUBLE_BOOKING: 'double-booking',
};
