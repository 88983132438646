import { getLocale } from '@/utils/locale';

export default function (value, opts = {}) {
  if (!value || !Number.isFinite(value)) {
    return 0;
  }

  const style = opts.style ?? 'decimal';
  const minimumFractionDigits = opts.minimumFractionDigits ?? 0;
  const maximumFractionDigits = opts.maximumFractionDigits ?? 2;
  const useGrouping = opts.useGrouping ?? true;

  const locale = getLocale();
  const intl = new Intl.NumberFormat(locale, {
    style,
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping,
  });

  return intl.format(value);
}
